import React from "react"

export default ({ pageContext }) => {
  const { title, url } = pageContext
  
  const getEmbedUrl = (url) => {
    if(url.indexOf("/secret/") > -1){
      const urlParts = url.split("/secret/");
      const parts = urlParts[1].split("/");
      const id = parts[0];
      const token = parts[1];
      return `https://academy.23video.com/${id}.ihtml/player.html?token=${token}&source=embed&photo%5fid=${id}&autoPlay=1&showDescriptions=0&showLogo=0&socialSharing=0&defaultQuality=fullhd`;
    }

    return url;
  };

  return (
    <>
      <h1>{title}</h1>
      <div className="video23" style={{"--aspect-ratio":16/9}}>
        <iframe 
          title={title}
          src={getEmbedUrl(url)}
          frameBorder="0" 
          border="0" 
          scrolling="no" 
          mozallowfullscreen="0" 
          webkitallowfullscreen="0" 
          allowFullScreen="0" 
          allow="autoplay">
        </iframe>
      </div>
    </>
  )
}